import tw, { styled } from 'twin.macro';
import CoinpaperIcon from '../../../icons/coinpaperIcon.svg';

export const Container = styled.div<{ light: boolean }>`
  ${tw`flex items-end flex-shrink-0`};
  ${(props) => (props.light ? tw`md:text-white text-black` : tw`text-white`)};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  padding: 0.625rem 1rem 0.313rem 1rem;

  path {
    ${tw`fill-current`}
    ${(props) => (props.light ? tw`md:text-white text-black` : 'text-white')};
  }
`;

// TODO: make logo smaller on mobile devices
export const Logo = styled(CoinpaperIcon).attrs({ fill: 'black' })`
  ${tw`inline`};
`;
