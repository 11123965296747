import { Article, Global, Seo } from '@api/api';
import { CLOUDINARY_BASE_URL } from '@consts/env';
import { OpenGraphMedia } from 'next-seo/lib/types';
import { merge, pickBy } from 'ramda';

export const buildResourceSeoSettings = (
  globalSettings: Seo & Pick<Global, 'siteName'>,
  resourceSettings: Seo,
): Omit<Seo, 'id'> & Pick<Global, 'siteName'> => {
  const seoSettings = merge(
    globalSettings,
    pickBy((val) => Boolean(val), resourceSettings),
  );

  return {
    ...seoSettings,
    siteName: globalSettings.siteName,
  };
};

export const getResourceSeo = (
  seo: Seo,
  title?: string,
  description?: string,
  shareImage?: string,
): Seo => {
  return merge(
    {
      metaTitle: title,
      shareTitle: title,
      metaDescription: description,
      shareDescription: description,
      shareImage,
    },
    pickBy((value) => Boolean(value), seo),
  );
};

type OpenGraphConfig = {
  type: 'website' | 'article';
  url: string;
  sitename: string;
  title: string;
  description: string;
  image?: OpenGraphMedia;
};

export const buildOpenGraph = ({
  type,
  url,
  title,
  description,
  image,
  sitename,
}: OpenGraphConfig) => {
  return {
    type,
    url,
    site_name: sitename,
    title,
    description,
    ...(image && {
      images: [
        {
          ...image,
          type: `image/${image.url.split('.').pop()}`,
        },
      ],
    }),
  };
};

type ArticleOpenGraphConfig = Omit<OpenGraphConfig, 'type'> & {
  publishedTime: string;
  modifiedTime: string;
  tags: string[];
};

export const buildArticleOpenGraph = ({
  publishedTime,
  modifiedTime,
  tags,
  sitename,
  ...openGraphConfig
}: ArticleOpenGraphConfig) => {
  return {
    ...buildOpenGraph({
      type: 'article',
      sitename: sitename || 'Coinpaper',
      ...openGraphConfig,
    }),
    article: {
      publishedTime,
      modifiedTime,
      tags,
    },
  };
};

export const twitterCard = {
  cardType: 'summary_large_image',
  site: '@coinpapercom',
};

type ArticleOpenGraphImage = {
  shareImage?: Seo['shareImage'];
  articleThumbnail?: Article['thumbnail'];
};

export const replaceCloudinaryBaseUrl = (url: string): string => {
  return url.replace('https://res.cloudinary.com', CLOUDINARY_BASE_URL);
};

export const buildArticleOpenGraphImage = ({
  shareImage,
  articleThumbnail,
}: ArticleOpenGraphImage): OpenGraphMedia => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:

  if (shareImage?.url) {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:
      url: replaceCloudinaryBaseUrl(shareImage?.url),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:
      width: shareImage?.width,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:
      height: shareImage?.height,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:
      alt: shareImage?.alternativeText,
    } as OpenGraphMedia;
  }

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:
    url: replaceCloudinaryBaseUrl(articleThumbnail?.url),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:
    width: articleThumbnail?.size,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:
    height: articleThumbnail?.size,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:
    alt: articleThumbnail?.alternativeText,
  } as OpenGraphMedia;
};

export const buildFaviconTags = (url: string) => {
  const urlSegments = url.split('/');
  const uploadPathIndex = urlSegments.findIndex((path) => path === 'upload');
  const sizes = ['16', '32', '96', '180'];
  const faviconPlaceholderUrl = `${urlSegments
    .slice(0, uploadPathIndex + 1)
    .join('/')}/{placeholder}/${urlSegments.slice(uploadPathIndex + 2).join('/')}`;

  return sizes.map((size) => ({
    rel: 'icon',
    type: 'image/png',
    href: replaceCloudinaryBaseUrl(
      faviconPlaceholderUrl.replace('{placeholder}', `c_fill,h_${size},w_${size}`),
    ),
  }));
};
