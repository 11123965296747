import tw, { styled } from 'twin.macro';
import ArrowRightIconBase from '../../../icons/arrowRightIcon.svg';
import CloseIconBase from '../../../icons/closeIcon.svg';
import SearchIconBase from '../../../icons/searchIcon.svg';

export const Form = styled.form`
  ${tw`block md:inline-block relative w-full block flex-grow md:w-auto`};
  position: relative;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
`;

export const Input = styled.input`
  ${tw`min-w-full font-inter`};
  background: white;
  height: 48px;
  padding: 0 38px;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  ::placeholder {
    color: #a7a7a7;
    font-weight: 500;
    line-height: 14px;
  }
`;

export const Button = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  span {
    ${tw`sr-only`};
  }
`;

export const CloseIcon = styled(CloseIconBase)`
  path {
    stroke: black;
  }
`;

export const SearchIcon = styled(SearchIconBase)`
  position: absolute;
  left: 16px;
  top: 15px;

  path {
    stroke: black;
  }
`;

export const ArrowIcon = styled(ArrowRightIconBase)`
  path {
    stroke: black;
  }
`;
