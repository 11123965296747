import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { noop } from '@utils/noop';

type ContextType = {
  search: string | null;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const SearchContext = createContext<ContextType>({
  search: '',
  setSearch: noop,
});

export const useSearchContext = (): ContextType => useContext(SearchContext);
