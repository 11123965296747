export const sections = [
  {
    heading: 'Coinpaper key links',
    links: [
      { name: 'About', href: 'https://google.com/' },
      { name: 'Contact', href: 'https://google.com/' },
      { name: 'Career', href: 'https://google.com/' },
      { name: 'Partnership Opportunities', href: 'https://google.com/' },
      { name: 'Donate', href: 'https://google.com/' },
      { name: 'Privacy Policy', href: 'https://google.com/' },
    ],
  },
  {
    heading: 'News',
    links: [
      { name: 'About', href: 'https://google.com/' },
      { name: 'Contact', href: 'https://google.com/' },
      { name: 'Career', href: 'https://google.com/' },
      { name: 'Partnership Opportunities', href: 'https://google.com/' },
      { name: 'Donate', href: 'https://google.com/' },
      { name: 'Privacy Policy', href: 'https://google.com/' },
    ],
  },
  {
    heading: 'Guides',
    links: [
      { name: 'All guides (53)', href: 'https://google.com/' },
      { name: 'Bitcoin Guide', href: 'https://google.com/' },
      { name: 'Etherum Guide', href: 'https://google.com/' },
      { name: 'NFT Guide', href: 'https://google.com/' },
      { name: 'What are cryptocurrencies?', href: 'https://google.com/' },
      { name: 'What are NFTs?', href: 'https://google.com/' },
    ],
  },
  {
    heading: 'Features',
    links: [
      { name: 'All features (234)', href: 'https://google.com/' },
      { name: 'Profiles', href: 'https://google.com/' },
      { name: 'Interviews', href: 'https://google.com/' },
      { name: 'Reviews', href: 'https://google.com/' },
      { name: 'Editors’ Picks', href: 'https://google.com/' },
    ],
  },
  {
    heading: 'Assets',
    links: [
      { name: 'All Asets', href: 'https://google.com/' },
      { name: 'Crypto', href: 'https://google.com/' },
      { name: 'DeFi', href: 'https://google.com/' },
      { name: 'NFT', href: 'https://google.com/' },
    ],
  },
  {
    heading: 'Collections',
    links: [
      { name: 'All collections', href: 'https://google.com/' },
      { name: 'Ethereum 2.0', href: 'https://google.com/' },
      { name: 'Bitcoin Halving', href: 'https://google.com/' },
      { name: 'NFT Revolution', href: 'https://google.com/' },
    ],
  },
  {
    heading: 'Markets',
    links: [
      { name: 'World', href: 'https://google.com/' },
      { name: 'Europe', href: 'https://google.com/' },
      { name: 'Americas', href: 'https://google.com/' },
      { name: 'Asia', href: 'https://google.com/' },
    ],
  },
];

export const socials = {
  telegram: 'https://t.me/coinpapernews',
  cmc: 'https://coinmarketcap.com/community/profile/Coinpaper/',
  linkedin: 'https://www.linkedin.com/company/53157290/',
  twitter: 'https://x.com/coinpapercom',
  instagram: 'https://instagram.com/coinpapercom',
  facebook: 'https://facebook.com/coinpapercom',
};
