import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SearchContext } from '@contexts/SearchContext';

export const SearchProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const { pathname, query } = useRouter();
  const [search, setSearch] = useState<string>(String(query.query ?? ''));

  useEffect(() => {
    if (pathname !== '/search') {
      setSearch('');
    }
  }, [pathname]);

  return <SearchContext.Provider value={{ search, setSearch }}>{children}</SearchContext.Provider>;
};
