import { ReactElement } from 'react';
import { useNavbarContext } from '@contexts/NavbarContext';
import { buildSearchPageLink } from '@utils/link';
import { Button, Container, Icon, Text } from './NavbarSearch.styles';
import { PageLink } from '../../../common/PageLink';

export const NavbarSearch = (): ReactElement => {
  const { isMobileMenuOpen, isSearchInputVisible } = useNavbarContext();

  return (
    <Container isMobileMenuOpen={isMobileMenuOpen} isSearchInputVisible={isSearchInputVisible}>
      <PageLink href={buildSearchPageLink('')}>
        <Button as="a">
          <Icon />
          <Text>Search</Text>
        </Button>
      </PageLink>
    </Container>
  );
};
