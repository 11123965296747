import { useState } from 'react';
import { MEDIA_QUERIES, useMediaQuery } from '@student-coin/coinpaper-components';

export const useTabAction = () => {
  const isTabletWide = useMediaQuery(MEDIA_QUERIES.tablet);
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseEnter = () => {
    if (!isTabletWide) {
      return;
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isTabletWide) {
      return;
    }
    setIsHovered(false);
  };

  const handleTabClick = () => {
    if (isTabletWide) {
      return;
    }

    setIsActive((prevActive) => !prevActive);
  };

  return { handleMouseEnter, handleMouseLeave, handleTabClick, isHovered, isActive };
};

export const useActiveTabState = () => {
  const [activeTabName, setActiveTabName] = useState('');

  const handleTabClick = (tabName: string) => setActiveTabName(tabName);

  return { clickedTabName: activeTabName, handleTabClick };
};
