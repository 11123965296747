import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { noop } from '@utils/noop';

type ContextType = {
  isMobileMenuOpen: boolean;
  isSearchInputVisible: boolean;
  setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
};

export const NavbarContext = createContext<ContextType>({
  isMobileMenuOpen: false,
  isSearchInputVisible: false,
  setIsMobileMenuOpen: noop,
});

export const useNavbarContext = (): ContextType => useContext(NavbarContext);
