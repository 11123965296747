
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { AdScript } from '@api/api';
import { Page } from '@components/layout/Page';
import { GTM_ID } from '@consts/env';
import { SearchProvider } from '@providers/SearchProvider';
import { useAdPlacements } from '@queries/useAdPlacements';
import { CoinpaperThemeProvider, theme } from '@student-coin/coinpaper-components';
import { isDevelopment } from '@utils/isDevelopment';
import 'intl-pluralrules';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { ReactElement, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { GlobalStyles } from 'twin.macro';
import { TransformedGlobalAllData } from '../queries/useGlobal';

const AdPlacement = dynamic(
  () => import('@components/common/AdPlacement').then((mod) => mod.AdPlacement),
  { ssr: true },
);

interface CustomPageProps {
  showCoinPriceSlider?: boolean;
  showNewsletter?: boolean;
  preview?: boolean;
  previewSource?: 'list' | 'edit';
  editPreviewUrl?: string;
  noHeader?: boolean;
  noFooter?: boolean;
  noBackground?: boolean;
  noPadding?: boolean;
  adScripts?: AdScript[];
  globalSettings?: TransformedGlobalAllData;
  [key: string]: unknown;
}

const tagManagersArgs = {
  gtmId: GTM_ID,
};

const AdPlacementComponent = () => {
  const adPlacement = useAdPlacements({
    placement: 'header-banner',
    is_active: true,
  });

  if (adPlacement.data) {
    return <AdPlacement adPlacements={adPlacement.data} placement="header-banner" />;
  }

  return null;
};

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>): ReactElement {
  const [queryClient] = useState(
    () => new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } }),
  );

  const {
    showCoinPriceSlider,
    showNewsletter,
    preview,
    previewSource,
    editPreviewUrl,
    noHeader,
    noFooter,
    noBackground,
    noPadding,
    adScripts,
    globalSettings,
    ...otherPageProps
  } = pageProps;

  useEffect(() => {
    if (tagManagersArgs.gtmId) {
      TagManager.initialize(tagManagersArgs);
    }
  }, []);

  return (
    <>
      <CoinpaperThemeProvider theme={theme}>
        <SearchProvider>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <AdPlacementComponent />
              <Page
                showCoinPriceSlider={showCoinPriceSlider}
                preview={preview}
                previewSource={previewSource}
                showNewsletter={showNewsletter}
                editPreviewUrl={editPreviewUrl}
                noHeader={noHeader}
                noFooter={noFooter}
                noBackground={noBackground}
                noPadding={noPadding}
                adScripts={adScripts}
                globalSettings={globalSettings}
              >
                <Component preview={preview} {...otherPageProps} />
              </Page>
            </Hydrate>
            {isDevelopment() && <ReactQueryDevtools />}
          </QueryClientProvider>
        </SearchProvider>
      </CoinpaperThemeProvider>
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  