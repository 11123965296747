import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';
import ArrowDownIcon from '../../../icons/arrowDownIcon.svg';
import ArrowDownSmIcon from '../../../icons/arrowDownSmIcon.svg';
import { Ul as Dropdown } from '../NavbarDropdown/NavbarDropdown.styles';

export const Ul = styled.ul<{ isMobileMenuOpen: boolean }>`
  ${tw`bg-white text-black md:bg-black md:text-white m-0 p-0`};
  ${(props) =>
    props.isMobileMenuOpen
      ? tw`w-full block flex-grow md:flex md:items-center justify-end md:w-auto`
      : tw`md:block hidden`};

  @media ${MEDIA_QUERIES.tablet} {
    border-top: none;
  }
`;

const onHoverStyle = css`
  text-decoration: none;
  -webkit-text-stroke: 1px black;

  @media ${MEDIA_QUERIES.tablet} {
    text-decoration: underline;
    -webkit-text-stroke: 1px white;
  }
`;

export const NavbarMenuItem = styled.span<{
  light?: boolean;
  active?: boolean;
  hover?: boolean;
  underline?: boolean;
}>`
  ${tw`block md:inline-block bg-white md:bg-black font-inter`};
  padding: 0 10px;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  ${(props) => (props.light ? tw`text-black` : tw`md:text-white text-black`)};
  ${(props) => (props.active ? onHoverStyle : '')};

  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #000000;

  &:hover,
  &:active {
    ${(props) => props.hover && onHoverStyle};
  }

  svg {
    margin-left: 8px;
  }

  @media ${MEDIA_QUERIES.tablet} {
    padding: 0 6px;
    line-height: 18px;
    height: auto;
  }
  @media ${MEDIA_QUERIES.laptop} {
    padding: 0 10px;
  }
`;

const expandedLiItemStyles = css`
  ${Dropdown} {
    ${tw`block`};
  }

  path {
    transform: rotate(180deg);
    transform-box: fill-box;
    transform-origin: center;
  }
`;

const notExpandedLiItemStyles = css`
  ${Dropdown} {
    ${tw`hidden`};
  }
  path {
    transform: rotate(0deg);
  }
`;

export const Li = styled.li<{
  expanded?: boolean;
  icon?: boolean;
  border?: boolean;
  visibleOn?: {
    mobile?: boolean;
    tablet?: boolean;
    desktop?: boolean;
  };
}>`
  ${tw`block md:inline-block relative`};
  line-height: 0;

  & > ${NavbarMenuItem} {
    border-left: 3px solid transparent;
  }

  border: none;
  padding: 0;

  ${(props) => (props.expanded && !props.icon ? expandedLiItemStyles : notExpandedLiItemStyles)};

  &:first-child {
    margin-right: 5px;
    @media ${MEDIA_QUERIES.laptop} {
      margin-right: 15px;
    }
  }

  &:hover > ${NavbarMenuItem}:first-child {
    /* -webkit-text-stroke: 1px black; */
    border-left: 3px solid black;

    @media ${MEDIA_QUERIES.tablet} {
      -webkit-text-stroke: 1px white;
    }
  }
  @media ${MEDIA_QUERIES.mobileS} {
    display: ${(props) => (props.visibleOn?.mobile === false ? 'none' : 'block')};
  }
  @media ${MEDIA_QUERIES.tablet} {
    border: ${(props) => props.border && '1px solid white'};
    padding: ${(props) => props.border && '5px 0'};
    display: ${(props) => (props.visibleOn?.tablet === false ? 'none' : 'inline-block')};
  }
  @media ${MEDIA_QUERIES.laptop} {
    display: ${(props) => (props.visibleOn?.desktop === false ? 'none' : 'inline-block')};
  }
`;

export const ArrowIcon = styled(ArrowDownIcon)`
  position: absolute;
  display: inline;
  top: 21px;
  right: 17px;
`;

export const ArrowSmIcon = styled(ArrowDownSmIcon)`
  display: inline;
`;
