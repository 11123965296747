import React, { ReactElement, useEffect, useState } from 'react';
import { NavbarContext } from '@contexts/NavbarContext';
import { MEDIA_QUERIES, useMediaQuery } from '@student-coin/coinpaper-components';
import { Container, Wrapper } from './Navbar.styles';
import { NavbarProps } from './Navbar.types';
import { NavbarLogo } from '../NavbarLogo/NavbarLogo';
import { NavbarToggler } from '../NavbarToggler/NavbarToggler';
import { NavbarMenu } from '../NavbarMenu/NavbarMenu';
import { NavbarSearch } from '../NavbarSearch';
import { NavbarSearchInput } from '../NavbarSearchInput';

export function Navbar({ links = [], pathname, showSearch = false }: NavbarProps): ReactElement {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
  const isTabletWide = useMediaQuery(MEDIA_QUERIES.tablet);

  const onHamburgerButtonClick = () => {
    if (pathname !== '/search') {
      setIsSearchInputVisible((prevState) => !prevState);
    } else {
      setIsSearchInputVisible(true);
    }
    setIsMobileMenuOpen((prevState) => !prevState);
  };
  useEffect(() => {
    setIsSearchInputVisible(pathname === '/search' && !isTabletWide);
    setIsMobileMenuOpen(false);
  }, [isTabletWide, pathname]);

  return (
    <NavbarContext.Provider value={{ isMobileMenuOpen, isSearchInputVisible, setIsMobileMenuOpen }}>
      <Container isMobileMenuOpen={isMobileMenuOpen}>
        <Wrapper isMobileMenuOpen={isMobileMenuOpen}>
          <NavbarLogo />

          <NavbarMenu pathname={pathname} links={links} />

          {showSearch && (
            <>
              <NavbarSearchInput />
              <NavbarSearch />
            </>
          )}

          <NavbarToggler onClick={onHamburgerButtonClick} />
        </Wrapper>
      </Container>
    </NavbarContext.Provider>
  );
}
