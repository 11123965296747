import { FooterSections } from '@api/api';
import { FooterSection } from '@components/layout/Footer/Footer/Footer.types';
import { buildLinkData } from './link';

const buildFooterSection = ({ name, links }: FooterSections): FooterSection => {
  return {
    heading: name,
    links: links.map(({ name: linkName, url }) => buildLinkData(linkName, url)),
  };
};

export const buildFooterSections = (sections: FooterSections[]): FooterSection[] => {
  if (!sections) {
    return [];
  }

  return sections.map(buildFooterSection);
};
