import { AxiosInstance } from 'axios';
import camelcaseKeys from 'camelcase-keys';

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.response.use((response) => {
    response.data = camelcaseKeys(response.data, { deep: true });
    return response;
  });

  return axiosInstance;
}
