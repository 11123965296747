import { useNavbarContext } from '@contexts/NavbarContext';
import { buildInternalLink } from '@utils/link';
import { FunctionComponent } from 'react';
import { NavbarDropdown } from '../NavbarDropdown/NavbarDropdown';
import { NavbarLink } from '../NavbarLink/NavbarLink';
import { useActiveTabState, useTabAction } from './NavbarMenu.hooks';
import { ArrowIcon, ArrowSmIcon, Li, NavbarMenuItem, Ul } from './NavbarMenu.styles';
import { NavLinkProps, NavbarMenuItemProps, NavbarMenuProps } from './NavbarMenu.types';

const NavLink: FunctionComponent<NavLinkProps> = ({
  active,
  href,
  name,
  dropdown,
  isLast,
  handleNavLinkClick,
  activeTabName,
  icon,
  border,
  hover = true,
  nofollow,
  underline,
  visibleOn
}) => {
  const { setIsMobileMenuOpen, isMobileMenuOpen } = useNavbarContext();
  const { handleMouseEnter, handleMouseLeave, handleTabClick, isActive, isHovered } =
    useTabAction();
  const hasActiveState = activeTabName === name && isActive;
  const hasDropdown = Boolean(dropdown?.length);
  const onNavLinkClick = () => {
    if (hasDropdown) {
      handleTabClick();
      handleNavLinkClick(name);
      return;
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <Li
      expanded={isHovered || hasActiveState}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      icon={!!icon}
      border={border}
      visibleOn={visibleOn}
    >
      {/* TODO: find a better solution than using 'as' prop */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <NavbarMenuItem active={active} onClick={onNavLinkClick} tabIndex="0" role="button" hover={hover} underline={underline}>
        {hasDropdown ? (
          name
        ) : (
          <NavbarLink href={href} nofollow={nofollow}>
            {name} {icon && icon}
          </NavbarLink>
        )}
        {hasDropdown && <ArrowSmIcon />}
        {isMobileMenuOpen && hasDropdown && <ArrowIcon />}
      </NavbarMenuItem>

      {hasDropdown && <NavbarDropdown name={name} dropdown={dropdown} isLast={isLast} />}
    </Li>
  );
};

const NavLinks: FunctionComponent<NavbarMenuProps> = ({ links, pathname }) => {
  const { handleTabClick, clickedTabName } = useActiveTabState();

  return (
    <>
      {links.map(
        ({ name, href = '#', dropdown, icon, border, hover, nofollow, underline, visibleOn }: NavbarMenuItemProps, index) => (
          <NavLink
            handleNavLinkClick={handleTabClick}
            activeTabName={clickedTabName}
            key={name}
            name={name}
            href={href}
            icon={icon}
            active={buildInternalLink(pathname) === href}
            dropdown={dropdown}
            isLast={index + 1 === links.length}
            border={border}
            hover={hover}
            nofollow={nofollow}
            underline={underline}
            visibleOn={visibleOn}
          />
        ),
      )}
    </>
  );
};

export const NavbarMenu: FunctionComponent<NavbarMenuProps> = ({ pathname, links = [] }) => {
  const { isMobileMenuOpen, isSearchInputVisible } = useNavbarContext();

  if (isSearchInputVisible && !isMobileMenuOpen) {
    return null;
  }

  return (
    <Ul isMobileMenuOpen={isMobileMenuOpen}>
      <NavLinks links={links} pathname={pathname} />
    </Ul>
  );
};
