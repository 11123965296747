import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useNavbarContext } from '@contexts/NavbarContext';
import { useSearchContext } from '@contexts/SearchContext';
import { ArrowIcon, Button, Form, CloseIcon, Input, SearchIcon } from './NavbarSearchInput.styles';

export const NavbarSearchInput = (): ReactElement => {
  const { t } = useTranslation('navbar');
  const router = useRouter();
  const { isMobileMenuOpen, isSearchInputVisible, setIsMobileMenuOpen } = useNavbarContext();
  const { search, setSearch } = useSearchContext();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const goToSearch = () => {
    router.push({
      pathname: '/search',
      query: { query: search },
    });
    return setIsMobileMenuOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return goToSearch();
  };

  const onButtonClick = () => {
    if (!isMobileMenuOpen && search.length !== 0) {
      return setSearch('');
    }
    return goToSearch();
  };

  if (isMobileMenuOpen || isSearchInputVisible) {
    return (
      <Form onSubmit={handleSubmit}>
        <SearchIcon />
        <Input
          autoFocus={!isMobileMenuOpen}
          type="text"
          placeholder={t('search-placeholder')}
          value={search}
          onChange={handleChange}
        />
        <Button onClick={() => onButtonClick()} type="button">
          {isMobileMenuOpen || search.length === 0 ? <ArrowIcon /> : <CloseIcon />}
          <span>{t('search-close')}</span>
        </Button>
      </Form>
    );
  }

  return null;
};
