import { FunctionComponent } from 'react';
import { PageLink } from '../../../common/PageLink';
import { Link } from './NavbarLink.styles';
import { NavbarLinkProps } from './NavbarLink.types';

export const NavbarLink: FunctionComponent<NavbarLinkProps> = ({
  className,
  target = '_self',
  children,
  onClick,
  href,
  nofollow = false,
}) => {
  return (
    <PageLink href={href}>
      {/* NextLink takes care of passing href to anchor element */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <Link
        className={className}
        rel={`noopener noreferrer ${nofollow ? 'nofollow' : ''}`}
        target={target}
        onClick={onClick}
      >
        {children}
      </Link>
    </PageLink>
  );
};
