import SearchIcon from '@components/icons/searchIcon.svg';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import tw, { styled } from 'twin.macro';

export const Icon = styled(SearchIcon)`
  margin-top: 5px;
  margin-left: 20px;
  @media ${MEDIA_QUERIES.tablet} {
    margin: 0 10px;
  }
  @media ${MEDIA_QUERIES.laptopL} {
    margin-right: 0;
  }
`;

export const Text = styled.span`
  ${tw`font-inter`};
  padding: 10px;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  display: none;
  @media ${MEDIA_QUERIES.laptopL} {
    display: inline;
  }
`;

export const Container = styled.div<{ isMobileMenuOpen: boolean; isSearchInputVisible: boolean }>`
  ${(props) =>
    props.isMobileMenuOpen && props.isSearchInputVisible
      ? tw`hidden`
      : 'flex pl-2 py-1 text-white'};
`;

export const Button = styled.button`
  ${tw`flex place-items-center m-0 p-0`};

  ${Icon} {
    ${tw`mt-0`};

    path {
      ${tw`text-white`};
      stroke: white;
    }
  }

  @media ${MEDIA_QUERIES.tablet} {
    color: white;
  }
`;
