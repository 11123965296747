import React, { FunctionComponent } from 'react';
import { NavbarLink } from '@components/layout/Navbar/NavbarLink/NavbarLink';
import { useNavbarContext } from '@contexts/NavbarContext';
import { Container, Logo } from './NavbarLogo.styles';

export const NavbarLogo: FunctionComponent = () => {
  const { isMobileMenuOpen } = useNavbarContext();

  return (
    <Container light={isMobileMenuOpen}>
      <NavbarLink href="/">
        <Logo />
      </NavbarLink>
    </Container>
  );
};
