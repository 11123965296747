import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import tw, { styled } from 'twin.macro';

export const Button = styled.button<{ light: boolean }>`
  ${tw`block md:hidden border-none flex items-center ml-0 md:ml-2`};
  padding: 15px 10px 15px 5px;

  @media ${MEDIA_QUERIES.mobileM} {
    padding: 15px;
  }

  path {
    ${tw`fill-current`}
    ${(props) => (props.light ? tw`text-black` : tw`text-white`)};
    stroke: ${(props) => (props.light ? 'black' : 'white')};
  }
`;
