import React, { FunctionComponent } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useNavbarContext } from '@contexts/NavbarContext';
import { NavbarTogglerProps } from './NavbarToggler.types';
import { Button } from './NavbarToggler.styles';
import CloseIcon from '../../../icons/closeIcon.svg';
import HamburgerIcon from '../../../icons/hamburgerIcon.svg';

export const NavbarToggler: FunctionComponent<NavbarTogglerProps> = ({ onClick }) => {
  const { t } = useTranslation('navbar');
  const { isMobileMenuOpen } = useNavbarContext();

  return (
    <Button
      aria-label={isMobileMenuOpen ? t('menu-close') : t('menu-open')}
      onClick={onClick}
      light={isMobileMenuOpen}
    >
      {isMobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
    </Button>
  );
};
