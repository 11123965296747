import { AdPlacement } from '@api/api';
import { adPlacementApiClient } from '@api/clients';
import { UseQueryResult, useQuery } from 'react-query';

export const fetchAdPlacements = async (params?): Promise<AdPlacement[]> => {
  const { data } = await adPlacementApiClient.adPlacementsGet({}, { params });
  return data;
};

export const useAdPlacements = (params?, enabled = true): UseQueryResult<AdPlacement[]> => {
  return useQuery(['ad-placements', params], () => fetchAdPlacements(params), { enabled });
};
