import axios from 'axios';
import { API_URL } from '@consts/env';

import { setupInterceptorsTo } from './interceptors';

export const apiInstance = axios.create({
  baseURL: API_URL,
});

setupInterceptorsTo(apiInstance);
