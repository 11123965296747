import { urls } from '@consts/images';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';

const background = css`
  background-image: url(${urls.pageGradientBackgroundMobile}), url(${urls.pageMaskBackgroundMobile});
  background-repeat: no-repeat, no-repeat;
  @media (min-width: 542px) {
    background-image: url(${urls.pageGradientBackground}), url(${urls.pageMaskBackground});
    background-size: contain;
  }
`;

export const Background = styled.div<{ noBackground?: boolean }>`
  width: 100%;
  height: 1000px;
  position: absolute;
  z-index: -1;
  ${({ noBackground }) => !noBackground && background}
`;

export const Container = styled.main<{ noPadding?: boolean }>`
  ${tw`container relative mx-auto px-4 py-4 sm:py-9 bg-no-repeat`};
  min-height: 90vh;
  padding: ${({ noPadding }) => noPadding && '0'};
  @media (${MEDIA_QUERIES.laptopL}) {
    max-width: 1280px;
  }
  @media (${MEDIA_QUERIES.desktop}) {
    max-width: 1536px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
