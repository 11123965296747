import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import tw, { styled } from 'twin.macro';

export const Ul = styled.ul<{ isLast: boolean }>`
  ${tw`block md:absolute p-0`};
  ${(props) => (props.isLast ? 'right: 0' : '')};
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid black;

  a {
    ${tw`font-inter text-black`};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;

    &:hover,
    &:active {
      ${tw`text-white`};
      text-decoration: none;
    }
  }

  @media ${MEDIA_QUERIES.tablet} {
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: none;
    padding-top: 8px;
    margin-left: 10px;
  }
`;

export const Li = styled.li`
  width: 100%;
  min-width: 170px;
  ${tw`bg-white hover:bg-black block border-black`};
  border-bottom: 1px solid black;
  padding: 15px 15px 15px 24px;

  @media ${MEDIA_QUERIES.tablet} {
    padding: 10px;
  }
`;
