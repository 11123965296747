import { API_URL } from '@consts/env';
import { AxiosInstance } from 'axios';
import qs from 'qs';
import {
  AboutApi,
  AdPlacementApi,
  AdScriptApi,
  AffiliateLinksApi,
  ArticleApi,
  AssetApi,
  CategoryApi,
  CollectionApi,
  ContactApi,
  DeletedLinksApi,
  FooterApi,
  GlobalApi,
  HomepageApi,
  LinksApi,
  LogosPageApi,
  PrApi,
  PrivacyPolicyApi,
  ProxyApi,
  SearchApi,
  SubcategoryApi,
  TagApi,
  TermsOfUseApi,
  WriterApi,
} from './api';
import { apiInstance } from './axios';
import { Configuration } from './configuration';

const basePath = API_URL;

const configuration = new Configuration({
  baseOptions: {
    paramsSerializer(params) {
      return qs.stringify(params);
    },
  },
});

const args: [Configuration, string, AxiosInstance] = [configuration, basePath, apiInstance];

const assetsApiClient = new AssetApi(...args);
const articleApiClient = new ArticleApi(...args);
const categoryApiClient = new CategoryApi(...args);
const collectionApiClient = new CollectionApi(...args);
const footerApiClient = new FooterApi(...args);
const globalApiClient = new GlobalApi(...args);
const homepageApiClient = new HomepageApi(...args);
const searchApiClient = new SearchApi(...args);
const aboutApiClient = new AboutApi(...args);
const privacyPolicyApiClient = new PrivacyPolicyApi(...args);
const contactApiClient = new ContactApi(...args);
const termsOfUseApiClient = new TermsOfUseApi(...args);
const subcategoryApiClient = new SubcategoryApi(...args);
const linksApiClient = new LinksApi(...args);
const writerApiClient = new WriterApi(...args);
const adScriptApiClient = new AdScriptApi(...args);
const adPlacementApiClient = new AdPlacementApi(...args);
const proxyApiClient = new ProxyApi(...args);
const logosPageApiClient = new LogosPageApi(...args);
const tagsApiClient = new TagApi(...args);
const deletedLinksApiClient = new DeletedLinksApi(...args);
const affiliateLinksApiClient = new AffiliateLinksApi(...args);
const prApiClient = new PrApi(...args);

export {
  aboutApiClient,
  adPlacementApiClient,
  adScriptApiClient,
  affiliateLinksApiClient,
  articleApiClient,
  assetsApiClient,
  categoryApiClient,
  collectionApiClient,
  contactApiClient,
  deletedLinksApiClient,
  footerApiClient,
  globalApiClient,
  homepageApiClient,
  linksApiClient,
  logosPageApiClient,
  prApiClient,
  privacyPolicyApiClient,
  proxyApiClient,
  searchApiClient,
  subcategoryApiClient,
  tagsApiClient,
  termsOfUseApiClient,
  writerApiClient,
};
