import { getUrl } from '@utils/getUrl';

export const urls = {
  pageGradientBackground: getUrl('img', 'gradient-background.webp'),
  pageGradientBackgroundMobile: getUrl('img', 'gradient-background-mobile.webp'),
  pageMaskBackground: getUrl('img', 'mask-background.webp'),
  pageMaskBackgroundMobile: getUrl('img', 'mask-background-mobile.webp'),
  notFoundBannerMask: getUrl('img', 'not-found-mask.webp'),
  searchLoader: getUrl('img', 'search-loader.gif'),
  logo: getUrl('img', 'logo.png'),
};
