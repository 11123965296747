export const API_URL = process.env.NEXT_PUBLIC_API_URL ?? process.env.STORYBOOK_API_URL;
export const APP_URL =
  process.env.NEXT_PUBLIC_VERCEL_URL && process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : process.env.NEXT_PUBLIC_APP_URL ?? process.env.STORYBOOK_APP_URL;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? '';
export const PREVIEW_PAGE_SECRET_TOKEN = process.env.PREVIEW_PAGE_SECRET_TOKEN ?? '';
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const MAILERLITE_GROUP_ID = process.env.MAILERLITE_GROUP_ID;
export const MAILERLITE_API_KEY = process.env.MAILERLITE_API_KEY;
export const ENVIRONMENT = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

// The base url without the trailing slash
export const CLOUDINARY_BASE_URL =
  process.env.CLOUDINARY_BASEURL || process.env.NEXT_PUBLIC_CLOUDINARY_BASEURL;

export const CLOUDINARY_NAME =
  process.env.CLOUDINARY_NAME || process.env.NEXT_PUBLIC_CLOUDINARY_NAME;

export const REVALIDATE_SECRET =
  process.env.REVALIDATE_SECRET || process.env.NEXT_PUBLIC_REVALIDATE_SECRET;
