import { TransformedNavbarCategories } from '@queries/useGlobal';
import { buildCategoryPageLink, buildLinkData, buildSubcategoryPageLink } from './link';

const buildNavbarLink = ({ name, slug, subcategories, displayAllCategory }) => {
  const dropdownLinks = [
    ...subcategories.map(({ subcategory }) =>
      buildLinkData(subcategory.name, buildSubcategoryPageLink(slug, subcategory.slug)),
    ),
  ];

  return {
    ...buildLinkData(name, buildCategoryPageLink(slug)),
    dropdown: displayAllCategory
      ? [buildLinkData(`All ${name}`, buildCategoryPageLink(slug)), ...dropdownLinks]
      : dropdownLinks,
  };
};

export const buildNavbarLinks = (links?: TransformedNavbarCategories[]) => {
  if (!links) {
    return [];
  }

  return links.map((item) => {
    if (item.category) {
      return buildNavbarLink({
        name: item.category.name,
        slug: item.category.slug,
        subcategories: item.subcategories,
        displayAllCategory: item.displayAllPageLink,
      });
    }

    const dropdownLinks = item.sublinks.map(({ name, url }) => buildLinkData(name, url));

    return {
      ...buildLinkData(item.link.name, item.link.url),
      dropdown: dropdownLinks,
    };
  });
};
