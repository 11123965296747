import { FC } from 'react';
import NextLink from 'next/link';
import { PageLinkProps } from './PageLink.types';

export const PageLink: FC<PageLinkProps> = ({ href, children }) => {
  return (
    <NextLink href={href} passHref>
      {children}
    </NextLink>
  );
};
