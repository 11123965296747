import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import tw, { styled } from 'twin.macro';
import { Container as NavbarLogo } from '../NavbarLogo/NavbarLogo.styles';
import { Ul as NavbarMenu } from '../NavbarMenu/NavbarMenu.styles';
import { Container as NavbarSearch } from '../NavbarSearch/NavbarSearch.styles';
import { Form as NavbarSearchInput } from '../NavbarSearchInput/NavbarSearchInput.styles';
import { Button as NavbarToggler } from '../NavbarToggler/NavbarToggler.styles';

export const Container = styled.div<{ isMobileMenuOpen: boolean }>`
  ${tw`relative z-10`};
  ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? tw`md:bg-black bg-white` : tw`bg-black`)};
`;

export const Wrapper = styled.nav<{ isMobileMenuOpen: boolean }>`
  ${tw`flex items-center flex-wrap`};

  @media ${MEDIA_QUERIES.laptopL} {
    ${tw`mx-auto`};
    max-width: 1280px;
  }

  @media ${MEDIA_QUERIES.desktop} {
    max-width: 1536px;
  }

  ${NavbarLogo} {
    ${tw`order-1 flex-grow`};
  }
  ${NavbarMenu} {
    ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? tw`md:order-2 order-4` : tw`order-2`)}
  }

  ${NavbarSearch} {
    ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? tw`order-3` : tw`order-3`)}
  }

  ${NavbarSearchInput} {
    ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? tw`order-3` : tw`order-5 md:order-3`)}
  }

  ${NavbarToggler} {
    ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? tw`md:order-4 order-2` : tw`order-4`)}
  }
`;
